/*global chrome*/

import { getApp, initializeApp } from "firebase/app";

import { 
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    signInWithCustomToken, 
    sendEmailVerification
} from "firebase/auth"

import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCsbwv2Z2vCm5HQwGmJa13Ps5OcuRb_Ero",
    authDomain: "crumbly-30085.firebaseapp.com",
    projectId: "crumbly-30085",
    storageBucket: "crumbly-30085.appspot.com",
    messagingSenderId: "453959487131",
    appId: "1:453959487131:web:1f7aa08a55247cec68003e",
    measurementId: "G-YXX98W2MZM"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

/* All of firebase functions return promises */

export const registerWithEmailAndPassword = (email, password) =>{
    return createUserWithEmailAndPassword(auth, email, password)
}

export const signInWithGoogle = () =>{
    return signInWithPopup(auth, googleProvider)
}

export const logInWithEmailAndPassword = (email, password) =>{
    return signInWithEmailAndPassword(auth, email, password)
}

export const sendPasswordReset = (email) =>{
    return sendPasswordResetEmail(auth, email)
}

export const logInWithCustomToken = (token) =>{
    return signInWithCustomToken(auth, token)
}

export const sendVerificationEmail = () =>{
    return sendEmailVerification(auth.currentUser)
}

export const logout = () =>{
    return signOut(auth)
}

export const logoutFirebase = (e) =>{
    const extId = process.env.REACT_APP_EXTID
    logout()
      .then(() =>{
        //user logged out of web app, convey this to extension to log out and remove cached tokens
        console.log('sending logout message to extension')
        chrome.runtime.sendMessage(extId, {logoutMessage: process.env.REACT_APP_LOGOUT_MESSAGE}, resFromExtension =>{
        //   if(!resFromExtension.success){
        //     console.log(resFromExtension)
        //   }

        })
      })
      .catch(err =>{
        console.log(err)
      })
  }

/* Firebase Functions */

export const functions = getFunctions(getApp());
connectFunctionsEmulator(functions, "localhost", 3000); //5001