export const brandcolors = {
    100: "#90A3A9",
    200: "#82A3A1",
    300: "#312F33",
    400: "#E9E9E9",
    500: "#D6D6D6",
    600: "#B7C1CC",
    700: "#465362",
    errors: {
        normal: "#D8C0C1",
        light: "#E8D9DA",
        bold: "#F6BDD1",
    },
    success: {
        normal: "#9FC490",
        light: "#C0DFA1"
    },

}

export const dashboardcolors = {
    topnav: {
        base: "#989898"
    },
    tabs: {
        background: "#FBFBFB"
    }
}