import React, { useEffect, useState } from 'react'

export default function useCenterLayout() {
  var [centerWidth, setCenterWidth] = useState(window.innerWidth);
  var [centerHeight, setCenterHeight] = useState(window.innerHeight);


  const onResizeSet = (e) => {
    setCenterWidth(window.innerWidth);
    setCenterHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", onResizeSet);

    return () => window.removeEventListener("resize", onResizeSet);
  }, []);

  return [centerWidth, centerHeight];
}
