import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import {
  ChakraProvider,
  ColorModeScript,
} from "@chakra-ui/react";
import theme from "chakratheme";
import { ReactQueryDevtools } from "react-query/devtools"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60000
    }
  }
})

const root = (
  <ChakraProvider>
      <ColorModeScript initialColorMode="light" />
      <QueryClientProvider client={queryClient} >
        <App />
        <ReactQueryDevtools />
      </QueryClientProvider>
  </ChakraProvider>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
