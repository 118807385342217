import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React, {useState} from 'react'
import {FaSearch} from "react-icons/fa";
import {useHistory} from "react-router-dom"
import * as ROUTES from 'routes.js'
const SearchVault = () => {

    let history = useHistory()
    const [query, setQuery] = useState('')
    const handleChange = (e) => {
        e.preventDefault();
        if(e.currentTarget.value !== ''){
            setQuery(e.currentTarget.value)
        }
    }

    const handleSubmit = (e) => {
        if(e.key === "Enter"){
            history.push(`${ROUTES.SEARCHPAGE}?query=${query}`)
        }

    }
  return (
    <InputGroup>
        <InputLeftElement pointerEvents="none" children={<FaSearch />} />
        <Input
            placeholder="Search my vault"
            variant="solid"
            w="450px"
            onChange={handleChange}
            onKeyDown={handleSubmit}
        ></Input>
    </InputGroup>


  )
}

export default SearchVault