import create from 'zustand'

const useEmailStore = create((set) => ({
  currentEmailAccount: '',
  setCurrentEmailAccount: (email) => set((state) => ({currentEmailAccount: email})),
  currentTags: [],
  setCurrentTags: (tags) => set((state) => ({currentTags: tags})),
  numsignups: 0,
  setnumsignups: (num) => set((state) => ({numsignups: num})),
  accountdescription: 'Describe an account',
  setaccountdescription: (description) => set((state) => ({accountdescription: description})),

  }
))

export { useEmailStore }