export function parseQuery( string ){

    var working_string = string.replace('?', '').split('&')

    var new_string = working_string.map( (element, index) =>{
        return element.split('=')
    }).flat()


    var keys = new_string.filter((element, index) => {
        return index % 2 === 0
    })

    var values = new_string.filter((element, index) => {
        return index % 2 !== 0
    })

    var obj = {}
    
    for(var i =0; i < keys.length; i++){
        obj[keys[i]] = values[i]
    }
    return obj
}