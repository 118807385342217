import React, { Component, useEffect } from "react";
import {
  Button,
  Box,
  HStack,
  Stack,
  VStack,
  Image,
  Badge,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Center,
  Heading,
  Text,
} from "@chakra-ui/react";
import { CustomLoadButton } from "components/custombutton";
import { useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { brandcolors } from "theme/brandcolors";
import { useHistory } from "react-router-dom";
import {
  auth,
  logInWithCustomToken,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { Users } from "axios.js";
import { GoogleAuthProvider } from "firebase/auth";
import {Link} from 'react-router-dom';
import * as ROUTES from 'routes.js'
export class SignupPage extends Component {
  state = {
    centerWidth: window.innerWidth,
    centerHeight: window.innerHeight,
  };

  onResize = (e) => {
    this.setState({
      centerWidth: window.innerWidth,
      centerHeight: window.innerHeight,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }
  render() {
    return (
      <Center w={this.state.centerWidth} h={this.state.centerHeight} style={{backgroundColor: '#121212'}}>
        <VStack spacing={12}>
          <Heading color={brandcolors[500]} size="lg">
            {/* You’re one step away from never doing this again. */}
            Just one more step to gain incognito powers for your digital life.
          </Heading>
          <VStack
            spacing={4}
            p={4}
            boxShadow="md"
            w="550px"
            h="450px"
            rounded="md"
          >
            <SignupTabs>
              <LoginForm />
              <SignupForm />
            </SignupTabs>
          </VStack>
        </VStack>
        
      </Center>
    );
  }
}

const SignupTabs = (props) => {
  var children = React.Children.toArray(props.children);

  return (
    <Tabs variant="unstyled" align="center" colorScheme="yellow" size="md">
      <TabList>
        <Tab
          color="yellow"
          _selected={{ borderBottom: "1px solid yellow" }}
          _focus={{ boxShadow: "none" }}
        >
          Login
        </Tab>
        <Tab
          color="yellow"
          _selected={{ borderBottom: "1px solid yellow" }}
          _focus={{ boxShadow: "none" }}
        >
          Sign Up
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{children[0]}</TabPanel>
        <TabPanel>{children[children.length - 1]}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const LoginForm = (props) => {
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const [showSpinningButton, setshowSpinningButton] = useState(true)
  var history = useHistory();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      history.replace(ROUTES.VERIFICATIONPAGE);
    }
    
  }, [user, loading, history]);
  /* SUBMIT */
  const signIn = (e) =>{
    setSubmit(true)

    console.log(`email ${email} ${typeof email}, password: ${password} ${typeof password}`)
    logInWithEmailAndPassword(email, password)
      .then(res =>{
        console.log(res)
      })
      .catch(err =>{
        console.log(err)

        //error validation
        if(err.message.includes('invalid-email') || err.message.includes('user-not-found') || err.message.includes('wrong-password')){
          setErrorMessage(`Invalid username or password.`)

        }
        setshowSpinningButton(true)
        setSubmit(false)
        setEmail('')
        setPassword('')

      })
  }
  const googleSignIn = (e) =>{
    signInWithGoogle()
      .then(res =>{
        const credential = GoogleAuthProvider.credentialFromResult(res)
        const token = credential.accessToken
        const user = res.user
        Users.signupgooglecrumblybackend(user.email, user.uid)
          .then(resBackend =>{
            if(resBackend.data.err){
              console.log(resBackend.data.err)
              return
            }
            history.replace(ROUTES.VERIFICATIONPAGE)
          })

      })
      .catch(err =>{
        console.log(err)
      })
  }

  /* EVENT METHODS */
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <form>
      <Stack spacing={6}>

        <InputGroup>
          {/* <InputLeftElement children={<Icon name="info" />} /> */}
          <Input required type="Email" placeholder="Email" onChange={onChangeEmail} value={email} aria-label="Email" color="white" />
        </InputGroup>
        <Input required type="password" placeholder="Password" onChange={onChangePassword} value={password} aria-label="Password" color="white" />

        <Text color="yellow">{errorMessage}</Text>

        {submit === false ? (
          <Button
            type="submit"
            variant="solid"
            colorScheme="yellow"
            onClick={signIn}
          >
            Login
          </Button>
        ) : (
          showSpinningButton && <CustomLoadButton></CustomLoadButton>
        )}

        <Button colorScheme="yellow" variant="outline" leftIcon={<FaGoogle />}  onClick={googleSignIn}>
          Sign In with Google
        </Button>
        <Text  color='white' style={{cursor: 'pointer'}}><Link to={ROUTES.FORGOTPASSWORDPAGE}>Forgot Password</Link> </Text>

      </Stack>
    </form>
  );
};

const SignupForm = (props) => {
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [showSpinningButton, setshowSpinningButton] = useState(true)

  const [passwordError, setpasswordError] = useState('')
  var history = useHistory();

  const minimumPasswordLength = 8
  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      history.replace(ROUTES.VERIFICATIONPAGE);
    }
  }, [user, loading, history]);

  useEffect(() =>{
    if(password.length >0 && confirmPassword.length > 0 && confirmPassword !== password) {
      setErrorMessage('Password must match.')
    }
    else{
      setErrorMessage('')
    }
  }, [password, confirmPassword])
  /* SUBMIT */
  const signUp = (e) => {
    e.preventDefault();
    setSubmit(true);
    // setshowSpinningButton(true)
    // if (errorMessage.length > 0) {
    //   return;
    // }
    // alert('hello')

    Users.signup(email, password).then((resSignup) => {

      console.log(resSignup);

      const {token} = resSignup.data
      logInWithCustomToken(token)
        .then((res) => {
          history.replace(ROUTES.VERIFICATIONPAGE);

        })
        .catch((err) => {
          console.log(err);

          if(err.message.includes('internal-error')){
            setErrorMessage(`Email may already be in use or is invalid. Try another.`)
  
          }
          setshowSpinningButton(false)
          setSubmit(false)
          setEmail('')
          setPassword('')
  
        });
    });

  };

  const googleSignUp = (e) =>{
    signInWithGoogle()
      .then(res =>{
        const credential = GoogleAuthProvider.credentialFromResult(res)
        const token = credential.accessToken
        const user = res.user
        Users.signupgooglecrumblybackend(user.email, user.uid)
          .then(resBackend =>{
            if(resBackend.data.err){
              console.log(resBackend.data.err)
              return
            }
            history.replace(ROUTES.VERIFICATIONPAGE)
          })

      })
      .catch(err =>{
        console.log(err)
      })
  }
  /* EVENT METHODS */
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    if(e.target.value.length < minimumPasswordLength){
      setpasswordError('Password must be at least 8 characters.')
    }
    else{
      setpasswordError('')
    }
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  var invalidSubmit =
    email.length === 0 ||
    // email.length > 50 ||
    password.length < minimumPasswordLength ||
    password !== confirmPassword;

  return (
    <form onSubmit={signUp}>
      <Stack spacing={4}>
        <InputGroup>
          {/* <InputLeftElement children={<Icon name="info" />} /> */}
          <Input
            type="Email"
            value={email}
            onChange={onChangeEmail}
            placeholder="Email"
            aria-label="Email"
            color="white"
          />
        </InputGroup>

        <Input
          type="password"
          value={password}
          onChange={onChangePassword}
          placeholder="Password"
          aria-label="Password"
          color="white"

        />
        <Input
          type="password"
          value={confirmPassword}
          onChange={onChangeConfirmPassword}
          placeholder="Confirm Password"
          aria-label="Confirm Password"
          color="white"

        />
        <Text color="yellow">{passwordError}</Text>
        <Text color='yellow'>{errorMessage}</Text>
        <Text  color='white'>By signing up, you agree to our {<Link style={{color: 'yellow'}} to={`${ROUTES.PRIVACYPOLICY}`}>Privacy Policy</Link>} and {<Link style={{color: 'yellow'}} to={`${ROUTES.TERMSOFUSE}`}>Terms of Service</Link>} </Text>

        {submit === false ? (
          <Button
            type="submit"
            variant="solid"
            colorScheme="yellow"
            onClick={signUp}
            disabled={invalidSubmit}
            // disabled={invalidSubmit}
          >
            Sign up
          </Button>
        ) : (
          showSpinningButton && <CustomLoadButton></CustomLoadButton>
        )}

        <Button colorScheme="yellow" variant="outline" leftIcon={<FaGoogle />} onClick={googleSignUp}>
          Sign Up with Google
        </Button>
      </Stack>
    </form>
  );
};
export default SignupPage;
