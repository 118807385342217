import { Box, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Links } from 'axios.js';
import React, {useState} from 'react'
import { FaLink } from 'react-icons/fa'
import { useQuery } from 'react-query';
import * as reactquerystrings from 'reactquery.js'
import { useEmailStore } from 'stores/emailStore';
import Loading2Lottie from './lotties/loading2';
import LinkIcon from 'icons/linkicon.png'

const Linkpreviewcomponent = (props) => {
    let { linkurl, index, date } = props

    // console.log(props);
    let formattedDate = new Date(date).toDateString()
    formattedDate = formattedDate.substring(4, formattedDate.length)
    const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
    const currentTags = useEmailStore(state => state.currentTags)

    const {data: linkdata, isLoading: isLoadingLink, isFetching: isFetchingLink, isError: isErrorLink} = useQuery([reactquerystrings.LINKPREVIEWBASE, linkurl + currentEmailAccount], () => Links.getLinkPreview(linkurl))
    const [error, setError] = useState(false)
    let maxDescriptionLength = 100
    if(isLoadingLink || isErrorLink || error) { 
        return (
            <Loading2Lottie width={100} height={100}></Loading2Lottie>
        )
    }
   

    const TagsRender = () => {
        if(currentTags.length === 0){
            return <Box bg='gray.100' rounded="lg" color="black" fontSize="xs" px={2} py={1} fontWeight="semibold">Untagged</Box>
        }
        return (
            <HStack>
                {
                    currentTags.map((tag, indextags) => {
                        return <Box key={`taglinkpreview${indextags}${currentEmailAccount}`} bg='#F8F4E3' rounded="lg" color="black" fontSize="xs" px={2} py={1} fontWeight="bold">{tag}</Box>
                    })
                }
            </HStack>
        )
    }

    //since we've established that we can get an error, just check for if there's an error and return a standard link preview component
    //don't make it fancy 
    //also make sure that when it tmesout, that it shows up here too
    //that and redirect function failure seem to be the only error cases
    if(linkdata.error){
        console.log('GOT ERROR_______________________:');
        let arrSegments = linkurl.split('https://')[1].split('.')
        let description = "Click here to see this site."
        
		let siteName = arrSegments[arrSegments.length - 2]
        return (
        <Box shadow="md"  w="650px" p={4} bg="#fff" rounded="lg" >
            <a href={linkurl} target="_blank" rel="noreferrer">
                <Box>
                    <HStack  p={0} border="2px" borderColor="#ddd" spacing={16}>
                        <VStack w="65%">
                            <Heading as='h3' size="md" fontWeight="semibold">{siteName}</Heading>
                            <Text color="gray" fontSize="xs" >{description.substring(0, maxDescriptionLength) + '...'}</Text>
                            
                            <HStack>
                                <FaLink size="25px" color="#ddd" />
                                <Text fontSize="xs" fontWeight="semibold" >{linkurl}</Text>
                            </HStack>
                        </VStack>
                        <Image src={LinkIcon} maxW="35%"  alt="" />
                        {/* {images.length > 0 && <Image src={images[0]} maxW="35%"  alt="" />} */}
                    </HStack>
                    <HStack justify="space-between">
                        <HStack  ><Text  fontWeight="semibold">Signed up: </Text> <Text fontWeight="bold" color="#9FC490">{formattedDate}</Text></HStack>
                    </HStack>
                </Box>
            </a>
        </Box>
        )
    }
    if(isErrorLink){
        console.log(isErrorLink);
        console.log(linkdata);
        return null
    }
    // console.log(linkdata.error);

    let {description, images, siteName, title, url, favicons} = linkdata.message
    if(!description)
        description = "Click here to see this site."
    if(!images)
        images = [] 
    if(!images && favicons && favicons.length > 0)
        images = favicons[0]
    if(!siteName){
        let arrSegments = linkurl.split('https://')[1].split('.')
		siteName = arrSegments[arrSegments.length - 2]
    }
    // if(!images && !favicons)


 
    return (
        // null
        <Box shadow="md"  w="650px" p={4} bg="#fff" rounded="lg" >
            <a href={linkurl} target="_blank" rel="noreferrer">
                <Box>
                    <HStack  p={0} border="2px" borderColor="#ddd" >
                        <VStack >
                            <Heading as='h3' size="md" fontWeight="semibold">{siteName}</Heading>
                            <Text color="gray" fontSize="xs" >{description.substring(0, maxDescriptionLength) + '...'}</Text>
                            
                            <HStack>
                                <FaLink size="25px" color="#ddd" />
                                <Text fontSize="md" fontWeight="semibold" >{linkurl}</Text>
                            </HStack>
                        </VStack>
                        {/* <img src={images[0]} width="30%" alt="" /> */}
                        {images.length > 0 && <Image src={images[0]} maxW="35%"  alt="" />}
                    </HStack>
                    <HStack justify="space-between" >
                        
                        <HStack ><Text alignSelf="end" fontWeight="semibold">Signed up: </Text> <Text fontWeight="bold" color="#9FC490">{formattedDate}</Text></HStack>
                    </HStack>
                </Box>
            </a> 
        </Box>
    )
}

export default Linkpreviewcomponent