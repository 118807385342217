import { extendTheme } from "@chakra-ui/react";

//global chakra overrides
const theme = extendTheme({
    colors: {
      brand: {
        100: "#90A3A9",
        200: "#82A3A1",
        300: "#312F33",
        400: "#E9E9E9",
        500: "#D6D6D6",
        600: "#B7C1CC",
        700: "#465362",
        800: "#E8D9DA",        
        900: "#C0DFA1",
      },
    },
  fonts: {
    heading: "Open Sans",
    body: "Raleway",
    p: "Neucha",
  },
});

export default theme;
