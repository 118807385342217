import { Spinner } from "@chakra-ui/react";
import React from "react";

const LoadingSpinner = (props) => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color={`${props.color ? props.color : "blue.500" }`}
      size={`${props.size ? props.size : "md" }`}
    />
  );
};

export default LoadingSpinner;
