import React, {useState, useEffect} from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import {
  auth,
  logInWithCustomToken,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle,
  sendVerificationEmail,
  logoutFirebase
} from "firebase.js";
import {onAuthStateChanged} from 'firebase/auth'
import {
  Button,
  Box,
  HStack,
  Stack,
  VStack,
  Image,
  Badge,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Center,
  AbsoluteCenter,
  Heading,
  Text,
} from "@chakra-ui/react";
import {FaEnvelope, FaSignOutAlt} from "react-icons/fa"

import * as ROUTES from 'routes.js'
import useCenterLayout from '../hooks/useCenterLayout.js'

const Verificationpage = () => {
  const [user, loading] = useAuthState(auth);
  var history = useHistory()

  const [centerWidth, centerHeight] = useCenterLayout()

  
  useEffect(() => {
    //may seem like code repeated twice, but this while only fire if the auth state changed (user verified email). the other will run every time
    onAuthStateChanged(auth, (user) =>{
      if(user){
        if(user.emailVerified === true){
          history.replace(ROUTES.DASHBOARD)

        }
      }
    })
    if(loading){
      // console.log('loading user information...', user)
      return
    }
    if(user){
      console.log(user)
      //request the server for a custom token with the user's uid and pass it to the chrome extension
      // var token = functions().httpsCallable('createToken')(user.uid) 
      if(user.emailVerified === true){
        history.replace(ROUTES.DASHBOARD)
      }
      else{
        sendVerificationEmail()
        .then(() =>{
            console.log('email sent!')
          })
      }
    }
    else{
      console.log('logged out')
      history.replace('/signup-login')
    }
  }, [user, loading, history])
  return (
      <Center w={centerWidth} h={centerHeight} style={{backgroundColor: '#1A1A1A'}}>

            <VStack
              spacing={4}
              p={4}
              boxShadow="md"
              w="750px"
              h="600px"
              rounded="md"
              bg='gray.100'
          >
            <AbsoluteCenter >
            <VStack spacing ={4}>
              <Text color='black' fontSize='4xl' fontWeight="bold">Just one more step and you're in!</Text>
              <Text color='black' fontSize='md' >Check your email (and spam folder!) and verify your account. </Text>
              <Text color='black' fontSize='md' >Once you click on the verification link, <b>you can return here and refresh the page.</b></Text>
              <Text color='black' fontSize='md' >If you did not receive an email, click the button below.</Text>


              <Button  leftIcon={<FaEnvelope />} colorScheme='green' variant='solid' onClick ={(e) =>{
                sendVerificationEmail()
                .then(() =>{
                    console.log('email sent!')
                  })
              }}>
                Resend Verification Email
              </Button>

              <Text mt={6} color='black' fontSize='xl' fontWeight="bold">Or you can log out.</Text>
              <Button  leftIcon={<FaSignOutAlt />} colorScheme='blue' variant='solid' onClick={(e) => logoutFirebase()}>
                Logout
              </Button>

            </VStack>

            </AbsoluteCenter>
          </VStack>
      </Center>
  )
}

export default Verificationpage
