import { Box, CSSReset, ThemeProvider } from "@chakra-ui/react";
import React, { Component } from "react";
import * as ROUTES from 'routes.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignupPage from "pages/signup-login";
import DashboardPage from "pages/dashboard";
import SearchPage from "pages/searchpage";
import Verificationpage from "pages/verificationpage";
import Forgotpasswordpage from "pages/forgotpasswordpage";
import Privacypolicy from 'pages/terms/privacypolicy.js'
import Termsconditions from 'pages/terms/termsconditions.js'


export function App() {
    return (
        <div >
          <Router>
            <Route exact path ={`/`} component={DashboardPage} />
            <Route exact path ={ROUTES.DASHBOARD} component={DashboardPage} />
            <Route exact path ={ROUTES.SIGNUP} component={SignupPage} />
            <Route exact path ={ROUTES.SEARCHPAGE} component={SearchPage} />
            <Route exact path ={ROUTES.VERIFICATIONPAGE} component={Verificationpage} />
            <Route exact path ={ROUTES.FORGOTPASSWORDPAGE} component={Forgotpasswordpage} />
            <Route exact path ={ROUTES.PRIVACYPOLICY} component={Privacypolicy} />
            <Route exact path ={ROUTES.TERMSOFUSE} component={Termsconditions} />



          </Router>
        </div>
    )
}

export default App;
