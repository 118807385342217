import { Box, Button, Divider, HStack, Input, Text, VStack } from '@chakra-ui/react'
import React, {useState, useEffect, useRef} from 'react'
import { brandcolors } from 'theme/brandcolors'
import AccountTagCloseButton from './accounttag'
import { FaEnvelope, FaLockOpen, FaPen, FaPiggyBank, FaPlus, FaQuestion, FaSearch, FaSignOutAlt, FaSortDown, FaStar, FaTint } from "react-icons/fa";
import { useQuery } from 'react-query';
import { useEmailStore } from 'stores/emailStore';
import { Email } from 'axios.js';

const AccountDetails = () => {
  var [mainTagColor, setMainTagColor] = useState("blue");
  const [showDescriptionInput, setshowDescriptionInput] = useState(false)
  const [description, setdescription] = useState('')
  const [showTagInput, setshowTagInput] = useState(false)
  const [tagValue, settagValue] = useState('')
  const [tagsrenderstate, settagsrenderstate] = useState([<></>])

  let inputRef = useRef(null)
  let tagInputRef = useRef(null)


  const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
  const numsignups = useEmailStore(state => state.numsignups)
  const accountdescription = useEmailStore(state => state.accountdescription)
  const setaccountdescription = useEmailStore(state => state.setaccountdescription)

  const [showTagsButton, setshowTagsButton] = useState(true)
  const currentTags = useEmailStore(state => state.currentTags)
  const setCurrentTags = useEmailStore(state => state.setCurrentTags)


  console.log(accountdescription);
//   const {data: metadata, isLoading: isLoadingMetadata, isFetching, isError} = useQuery([reactquerystrings.NUMSIGNUPS,currentEmailAccount ], () => Email.getAllEmailAccountsForUser(searchemail))
  
  let maxsignups = 25

//   if(!isLoadingMetadata){
//       maxsignups = metadata.data.
//   }

  const setAccountDescription = (e) =>{
    setshowDescriptionInput(!showDescriptionInput)
  }
  const addTag = (e) =>{
    setshowTagInput(!showTagInput)
  }


  const handleInputChange = (e) =>{
    setdescription(e.target.value)
  }
  const handleTagInputChange = (e) =>{
    settagValue(e.target.value)
  }

  
  const handleSubmit = (e) =>{
    if(e.key === 'Enter' && description !== ''){

      Email.setDescriptionForAccount(currentEmailAccount, description)
        .then(res =>{
          console.log(`made it to set ACCOUNT DESCRIPTION : description is ${description}`);
          setaccountdescription(description)
          setshowDescriptionInput(!showDescriptionInput)
    
        })
    }

    else if(e.key === 'Enter'){
      setshowDescriptionInput(!showDescriptionInput)

    }

  }
  const handleTagSubmit = (e) =>{
    if(e.key === 'Enter' && tagValue !== ''){

      Email.addTagForAccount(currentEmailAccount, tagValue)
        .then(res =>{
          setCurrentTags([...currentTags, tagValue])
          setshowTagInput(!showTagInput)
          settagValue('')
        
        })
    }
    else if(e.key === 'Enter'){
      setshowTagInput(!showTagInput)

    }
  }

  useEffect(() => {
    if(currentTags.length >= 2){
      setshowTagsButton(false)
    }
    else
      setshowTagsButton(true)

    if (showDescriptionInput && inputRef ){
      inputRef.current.focus()
    }

    if(showTagInput && tagInputRef){
      tagInputRef.current.focus()
    }

    return () => {
      
    }
  }, [showDescriptionInput, inputRef, currentTags, showTagInput, tagInputRef])
  
  // useEffect(() => {
  //   let tags = TagsRender()
  //   settagsrenderstate(tags)
  // }, [])
  

  const TagsRender = () =>{

    let tags = currentTags.map((tag, index) =>{
      if(index === 0)
        return <AccountTagCloseButton size="md" colorScheme={`blue`} tagLabelText={tag} />
      else
        return <AccountTagCloseButton size="md" colorScheme={`cyan`} tagLabelText={tag} />

    })
    
    return tags
  }
  return (
    <Box bg="white" boxShadow="md" rounded="lg" p={5} minW='400px' >
        <HStack mb={3}>
            {/* <AccountTagCloseButton size="md" colorScheme={mainTagColor} tagLabelText="Web Development" /> */}
            {TagsRender()}
            {showTagsButton && ( showTagInput ? <Input ref={tagInputRef} maxW="50%" onChange={handleTagInputChange} onKeyDown={handleTagSubmit} type="text" /> : <Button onClick={addTag}  _focus={{ boxShadow: "none", }} variant="ghost" color={brandcolors[500]} rightIcon={<FaPlus />}>Add Tag</Button>)}
        </HStack>
        <VStack align="start" divider={<Divider />}  spacing={3}>
            <HStack  >
                <FaTint size="24px" color={mainTagColor}/>
                {showDescriptionInput ? <div tabIndex={0}  ><Input ref={inputRef} onChange={handleInputChange} onKeyDown={handleSubmit} type="text" /></div> : <Text>{accountdescription === '' ? 'Describe this account to remember what you signed up for!' : accountdescription}</Text>}
                <FaPen  cursor='pointer' onClick={setAccountDescription} size="24px" alignSelf="end" opacity=".30" color={brandcolors[300]}/>
            </HStack>
            <HStack>
                <FaPiggyBank size="24px" color={mainTagColor}/>
                <Text><b>{numsignups}</b> signups</Text>
                {/* <FaQuestion size="20px" alignSelf="end" marginLeft="auto"  opacity=".30" color={brandcolors[300]}/> */}

            </HStack>
        </VStack>
    </Box>
  )
}

export default AccountDetails