import React, {useEffect, useState} from 'react'
import { parseQuery } from 'utils/helpers'
import { useLocation} from 'react-router-dom'
import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react'
import NewsearchLottie from 'components/lotties/newsearch'
import { Link } from 'react-router-dom'
import * as ROUTES from 'routes.js'
const SearchPage = () => {
    // let queryParams = {}
    const [queryParams, setQueryParams] = useState({})
    let location = useLocation()
    useEffect(() => {
        let q = {}
        q = parseQuery(location.search)
        setQueryParams(q)
        console.log(queryParams);
        
      
    }, [])
    
  return (
    <Box mt={8}>
        <Center>
            <VStack spacing={4}>
                <Heading as='h4' color="gray.500">You searched for <Text color="green.200">{queryParams.query}</Text></Heading>
                <NewsearchLottie width={500} height={500} />
                <Heading>Search Coming Soon</Heading>
                <Button size="lg" ><Link to={ROUTES.DASHBOARD}>Back to Dashboard</Link></Button>
            </VStack>
        </Center>
    </Box>
  )
}

export default SearchPage