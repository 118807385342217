import React, { useState, useEffect } from "react";
import Lottie from "react-lottie-player";

const Loading2Lottie = (props) => {
  const [animationData, setAnimationData] = useState();

  const {width, height} = props
  useEffect(() => {
    import("./loading2.json").then(setAnimationData);
  }, []);

  if (!animationData) return null
  return <Lottie loop play style={{width: width, height: height}} animationData={animationData} />;
};
export default Loading2Lottie;
