import { Box, Button, Center, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from '@chakra-ui/react';
import LoadingSpinner from 'components/loading/spinner';
import useCenterLayout from 'hooks/useCenterLayout';
import React, {useState, useEffect} from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { dashboardcolors } from 'theme/brandcolors';
import { logoutFirebase, auth, functions } from "firebase.js";
import { useEmailStore } from 'stores/emailStore';
import * as reactquerystrings from 'reactquery.js'
import { Email, Links } from 'axios.js';
import Linkpreviewcomponent from 'components/linkpreviewcomponent';
import {  FaSearch } from "react-icons/fa";
import { useQuery } from 'react-query';

const Signupstab = () => {

    var [centerWidth, centerHeight] = useCenterLayout();
    const [user, loading, error] = useAuthState(auth);
    const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
    const setCurrentEmailAccount = useEmailStore(state => state.setCurrentEmailAccount)
    const currentTags = useEmailStore(state => state.currentTags)

    // console.log(currentEmailAccount);
    // console.log(currentTags);

    const {data: numSignupData, isLoading: isLoadingnumSignup, isFetching: isFetchingnumSignup, isError: isErrornumSignup, refetch} = useQuery([reactquerystrings.NUMSIGNUPS, currentEmailAccount], () => Links.getNumSignupsForUser(currentEmailAccount))
    const pagelimit = 10
    // const {data: linkdata, isLoading: isLoadingLink, isFetching: isFetchingLink, isError: isErrorLink} = useQuery([reactquerystrings.LINKPREVIEWBASE, '1'], () => Links.getLinkPreview("https://www.anime-planet.com/anime/tags/boxing"))
    const [id_last, setid_last] = useState('')
    const [allSignups, setallSignups] = useState([])
    const [endReached, setEndreached] = useState(false)
    const [isExecuted, setIsExecuted] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [backupAllSignups, setBackupAllSignups] = useState([])
    const [numSignups, setNumSignups] = useState(0)

    const setnumsignupsglobal = useEmailStore(state => state.setnumsignups)


    const infiniteScroll = async (e) => {
        if ( window.scrollY > (document.body.offsetHeight - window.outerHeight) && !isExecuted) {

            //don't search anymore... reached end. If user wants to check again, they'll have to refresh page
            // if(allSignups.length !== 0 && allSignups.length < pagelimit){
            //     console.log('end already reached, exiting early without fetch...');
            //     return
            // }
            // Set "isExecuted" to "true" to prevent further execution

            setIsExecuted(true)

            // execute
            console.log("Working...");
            const data = await Links.getSignupsForUser(id_last, currentEmailAccount, pagelimit, 'more')
            //check for error and exit so they can try again
            if(data.error){
                console.log('got error from backend', data.error);
                setEndreached(true)

                setTimeout(() => {
                    setIsExecuted(false)
                }, 5000);
                return
            }
            //we've reached the end
            if(data.message.length < pagelimit){
                console.log('reached end. exiting...');
                setallSignups(allSignups.concat(data.message))
                setEndreached(true)

                console.log(endReached);
                setid_last(data.last)

                setTimeout(() => {
                    setIsExecuted(false)
                }, 5000);
                return
            }
            setid_last(data.last)
            setallSignups(allSignups.concat(data.message))

            setTimeout(() => {
                setIsExecuted(false)
            }, 1500);
        }
    }
    useEffect(() => {      
        document.addEventListener('scroll', infiniteScroll)
      return () => {
        document.removeEventListener('scroll', infiniteScroll)
      }

    }, [id_last, allSignups, endReached, isExecuted])
    
    
    useEffect(() => {
        console.log(`current email is ${currentEmailAccount}`);
        Links.getSignupsForUser('NOW()', currentEmailAccount, pagelimit, 'first')
        .then(res =>{
            // console.log(res)

            let arr = [...res.message]
            setid_last(res.last)
            setallSignups(arr)
            // forceUpdate()
            // console.log(allSignups);
            setNumSignups(numSignupData.message[0].count)
            setnumsignupsglobal(numSignupData.message[0].count)

        })
    
    }, [currentEmailAccount])

    useEffect(() => {
        if(!isLoadingnumSignup && !isErrornumSignup){
            // console.log("HERE_____", numSignupData.message[0].count );
            setNumSignups(numSignupData.message[0].count)
            setnumsignupsglobal(numSignupData.message[0].count)
        }
         
    }, [isLoadingnumSignup, isErrornumSignup,])
    

    if(isLoadingnumSignup){
        return null
    }
   
    const doSearchFilter = (e) =>{
        if(e.target.value === ''){
            // console.log('here');
            // console.log(backupAllSignups);
            setallSignups(backupAllSignups)
            return
        }
        // console.log(e.target.value);
        setallSignups(backupAllSignups)

        let filtered = allSignups.filter(function (obj){ 
            let formattedDate = new Date(obj.submitdate).toDateString()
            formattedDate = formattedDate.substring(4, formattedDate.length)
            let str = obj.url + ' ' + formattedDate

            // console.log(str);
            return str.indexOf(e.target.value) !== -1; 
        });
        setallSignups(filtered)


        // setallSignups(backupAllSignups, (currentSignups) => {
        //     let filtered = allSignups.filter(function (obj){ 
        //         let formattedDate = new Date(obj.submitdate).toDateString()
        //         formattedDate = formattedDate.substring(4, formattedDate.length)
        //         let str = obj.url + formattedDate

        //         console.log(str);
        //         return str.indexOf(e.target.value) !== -1; 
        //     });
        //     setallSignups(filtered)
        // })
      
    }
    const onFocusFilter = (e) =>{
        if(e.target.value === ''){
            setBackupAllSignups([...allSignups])
        }
        // console.log('at on focus filter________');
        // console.log(allSignups);

        // console.log(backupAllSignups);
    }
    const TagsRender = () => {
        if(currentTags.length === 0){
            return <Box bg='gray.100' rounded="lg" color="black" fontSize="xs" px={2} py={1} fontWeight="semibold">Untagged</Box>
        }
        return (
            <HStack>
                {
                    currentTags.map((tag, indextags) => {
                        return <Box key={`taglinkpreview${indextags}${currentEmailAccount}`} bg='#F8F4E3' rounded="lg" color="black" fontSize="xs" px={2} py={1} fontWeight="bold">{tag}</Box>
                    })
                }
            </HStack>
        )
    }
   

    
    
    
    // if(isExecuted /*|| isLoadingEmail*/){ 
    //     return (
    //     <LoadingSpinner></LoadingSpinner>
    //     )
    // }
    // console.log(allSignups);

    return (
        <Center w={centerWidth} h={centerHeight / 2}>
            <VStack spacing={4} bg={dashboardcolors.tabs.background} w="100%" h="100%" p={8}>
                {/* <Button onClick={refetch} colorScheme='teal' variant='ghost'>Refetch</Button> */}
                <InputGroup w="40%">
                    <InputLeftElement
                    pointerEvents='none'
                    color='gray.300'
                    fontSize='1.2em'
                    children={<FaSearch />}
                    />
                    <Input placeholder='Filter by url or date' onChange={doSearchFilter} onFocus={onFocusFilter} />
                </InputGroup>
                    <HStack>
                        <Text fontWeight='bold'>{currentEmailAccount || null}</Text>
                        {TagsRender()}
                    </HStack>
                    <Text>{numSignups} Total Signups</Text>
                    {
                        allSignups && allSignups.map((signupData, index) => <Linkpreviewcomponent key={`signuplink${index} ${currentEmailAccount}`} date={signupData.submitdate} linkurl={signupData.url} index={index} />)
                    }
                    {/* <Linkpreviewcomponent linkurl='https://www.anime-planet.com/anime/tags/boxing' index={1} /> */}
                    {/* <Linkpreviewcomponent linkurl='https://app.seochecklist.dev' index={1} /> */}

                    {isExecuted && <LoadingSpinner></LoadingSpinner>}
                    {endReached && <Text>You've reached the end!</Text>}


            </VStack>
        </Center>
    );
}

export default Signupstab