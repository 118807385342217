import LoadingSpinner from "components/loading/spinner";
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie-player";

const EmptyBoxLottie = () => {
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    import("./empty-box.json").then(setAnimationData);
  }, []);

  if (!animationData) return <LoadingSpinner></LoadingSpinner>;
  return <Lottie loop play style={{width: 150, height: 150}} animationData={animationData} />;
};
export default EmptyBoxLottie;
