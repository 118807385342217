import React, {useState, useEffect, useRef} from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import {
  auth,
} from "firebase.js";
import {sendPasswordResetEmail} from 'firebase/auth'
import {
  Button,
  Box,
  HStack,
  Stack,
  VStack,
  Image,
  Badge,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Center,
  AbsoluteCenter,
  Heading,
  Text,
} from "@chakra-ui/react";
import {FaEnvelope, FaSignOutAlt} from "react-icons/fa"

import * as ROUTES from 'routes.js'
import useCenterLayout from '../hooks/useCenterLayout.js'

const Forgotpasswordpage = () => {
  const [user, loading] = useAuthState(auth);
  var history = useHistory()

  const [centerWidth, centerHeight] = useCenterLayout()
  const [email, setEmail] = useState('')
  const inputRef = useRef()
  
//   useEffect(() => {
//     //may seem like code repeated twice, but this while only fire if the auth state changed (user verified email). the other will run every time
//     onAuthStateChanged(auth, (user) =>{
//       if(user){
//         if(user.emailVerified === true){
//           history.replace(ROUTES.DASHBOARD)

//         }
//       }
//     })
//     if(loading){
//       // console.log('loading user information...', user)
//       return
//     }
//     if(user){
//       console.log(user)
//       //request the server for a custom token with the user's uid and pass it to the chrome extension
//       // var token = functions().httpsCallable('createToken')(user.uid) 
//       if(user.emailVerified === true){
//         history.replace(ROUTES.DASHBOARD)
//       }
//       else{
//         sendVerificationEmail()
//         .then(() =>{
//             console.log('email sent!')
//           })
//       }
//     }
//     else{
//       console.log('logged out')
//       history.replace('/signup-login')
//     }
//   }, [user, loading, history])
const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  var invalidSubmit =
    email.length === 0 ||
    !email.includes('@')

  return (
      <Center w={centerWidth} h={centerHeight} style={{backgroundColor: '#1A1A1A'}}>

            <VStack
              spacing={4}
              p={4}
              boxShadow="md"
              w="750px"
              h="600px"
              rounded="md"
              bg='gray.100'
          >
            <AbsoluteCenter >
            <VStack spacing ={4}>
              <Text color='black' fontSize='4xl' fontWeight="bold">Reset Your Password</Text>
              <Text color='black' fontSize='md' >Type email below. Check your email (and spam folder!) to reset your password. </Text>
              <Text color='black' fontSize='md' >Follow the instructions. Make sure the sender is <b>noreply@crumbly.dev</b>.</Text>

                <Input ref={inputRef} required type="text" placeholder="Type your email" onChange={onChangeEmail} value={email} aria-label="Email" color="black" />


              <Button disabled={invalidSubmit} leftIcon={<FaEnvelope />} colorScheme='green' variant='solid' onClick ={(e) =>{
                sendPasswordResetEmail(auth, email)
                .then(() =>{
                    console.log('email sent!')
                    inputRef.current.value = ''
                  })
              }}>
                Send Password Reset Email
              </Button>

              {/* <Text mt={6} color='black' fontSize='xl' fontWeight="bold">Or you can return to the Login page.</Text> */}
              <Button  leftIcon={<FaSignOutAlt />} colorScheme='blue' variant='solid' onClick={(e) =>{
               history.replace(ROUTES.SIGNUP)
               }}>
                Return to Login
              </Button>

            </VStack>

            </AbsoluteCenter>
          </VStack>
      </Center>
  )
}

export default Forgotpasswordpage
