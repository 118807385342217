import React from 'react'
import {onAuthStateChanged} from 'firebase/auth'
import {
  Button,
  Box,
  HStack,
  Stack,
  VStack,
  Image,
  Badge,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Center,
  AbsoluteCenter,
  Heading,
  Text,
  List,
  ListItem
} from "@chakra-ui/react";
import {FaEnvelope, FaSignOutAlt} from "react-icons/fa"

import * as ROUTES from 'routes.js'
import useCenterLayout from '../../hooks/useCenterLayout.js'

const Termsconditions = () => {
  const [centerWidth, centerHeight] = useCenterLayout()

  return (
    <Box w={centerWidth} h={centerHeight} style={{backgroundColor: '#1A1A1A'}}>

      <VStack
              spacing={4}
              p={12}
              boxShadow="md"
            //   w="750px"
            //   h="600px"
              rounded="md"
              bg='gray.100'
          >
            <Text fontWeight='bold' fontSize="3xl">Terms of Service </Text>
            <Text>Thank you for choosing Crumbly ("Crumbly" or "we" or "us"). These terms of service ("Terms") govern your use of the Crumbly App software, application, and any associated services, features, or content made available through the Crumbly App (collectively, the "Service"). By using Crumbly, you agree to be bound by these Terms, which constitute a legally binding agreement between you and Crumbly. If you do not agree to these Terms, you may not use Crumbly.</Text>

            <Text fontWeight='bold' fontSize="2xl">Account Creation</Text>
            <Text>To use Crumbly, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</Text>

            <Text fontWeight='bold' fontSize="2xl">Service Use</Text>
            <Text>You may use Crumbly for personal use only. You agree not to abuse or maliciously use Crumbly or use Crumbly for illegal or nefarious purposes. We reserve the right to terminate your account and use of Crumbly if we believe you are violating any of these Terms.</Text>
            <Text fontWeight='bold' fontSize="2xl">User Conduct</Text>
            <Text>You agree to use Crumbly in compliance with all applicable laws and regulations. You may not use Crumbly to:</Text>
            <List>
                <ListItem>- Engage in any activity that is illegal or violates the rights of others;</ListItem>
                <ListItem>- Transmit or upload any material that contains viruses, Trojan horses, worms, or other harmful or disruptive components;</ListItem>
                <ListItem>- Interfere with Crumbly or servers or networks connected to Crumbly, or disobey any requirements, procedures, policies, or regulations of networks connected to Crumbly;</ListItem>
                <ListItem>- Attempt to gain unauthorized access to Crumbly or any portion of it, including without limitation, the accounts of other users; or</ListItem>
                <ListItem>- Use Crumbly for any purpose that is competitive with or harmful to Crumbly.</ListItem>

            </List>
            <Text></Text>
            <Text fontWeight='bold' fontSize="2xl">Intellectual Property</Text>
            <Text>The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Crumbly, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</Text>

            <Text fontWeight='bold' fontSize="2xl">Disclaimer of Warranties</Text>
            <Text>THE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND. CRUMBLY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</Text>

            <Text fontWeight='bold' fontSize="2xl">Limitation of Liability</Text>
            <Text>IN NO EVENT SHALL CRUMBLY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.</Text>

            <Text fontWeight='bold' fontSize="2xl">Indemnification</Text>
            <Text>You agree to defend, indemnify, and hold harmless Crumbly, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of Crumbly.</Text>

            <Text fontWeight='bold' fontSize="2xl">Data</Text>
            <Text>Crumbly stores all data within Europe. By using Crumbly, you agree that Crumbly may collect, use, and disclose information as outlined in our Privacy Policy. In addition, you agree that your data may be handed over if there is a legal requirement to do so. You acknowledge that you are solely responsible for any data that you upload or transmit through Crumbly, and you agree not to upload or transmit any data that infringes any intellectual property rights or that is illegal, harmful, or offensive.</Text>
            <Text>Crumbly takes reasonable steps to protect your data from loss, corruption, or unauthorized access. However, you agree that Crumbly will not be liable for any loss of data even though every attempt is made not to lose it or corrupt it.</Text>

            <Text fontWeight='bold' fontSize="2xl">Children Under 13</Text>
            <Text>Children under 13 years of age are not permitted to use Crumbly. If we become aware that a child under 13 has provided us with personal information, we will take steps to close accounts and delete any data associated to the account.</Text>

            <Text fontWeight='bold' fontSize="2xl">Termination</Text>
            <Text>Crumbly reserves the right to terminate your use of Crumbly for any reason, including if we believe that you have violated any of these Terms. Upon termination, all licenses and rights granted to you under these Terms will immediately terminate, and you must cease all use of Crumbly.</Text>

            <Text fontWeight='bold' fontSize="2xl">Changes to the Terms</Text>
            <Text>Crumbly may revise these Terms from time to time, and the most current version will always be posted on our website. If a revision, in our sole discretion, is material, we will notify you (for example, via email to the email address associated with your account). By continuing to access or use Crumbly after those revisions become effective, you agree to be bound by the revised Terms.</Text>

            <Text fontWeight='bold' fontSize="2xl">Miscellaneous</Text>
            <Text>These Terms, together with our Privacy Policy, constitute the entire agreement between you and Crumbly regarding your use of Crumbly. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect. These Terms shall be governed by the laws of the United States and the state of California, without regard to conflict of law principles.</Text>

            <Text fontWeight='bold' fontSize="2xl">Contact Us</Text>
            <Text>If you have any questions about these Terms or Crumbly or have any problems please contact us at crumblyhelp@gmail.com</Text>

            
          </VStack>
    </Box>
  )
}

export default Termsconditions
