import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { Email } from "axios.js";
import React from "react";
import { useEmailStore } from "stores/emailStore";

const AccountTagCloseButton = ({ size, colorScheme, tagLabelText }) => {
  const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
  const currentTags = useEmailStore(state => state.currentTags)
  const setCurrentTags = useEmailStore(state => state.setCurrentTags)

  const removeTag = (e) =>{

    Email.removeTagForAccount(currentEmailAccount, tagLabelText)
      .then(res =>{
        let tags = [...currentTags]
        tags = tags.filter(tag => tag !== tagLabelText)
        setCurrentTags(tags)
    
      })
  }

  return (

    <Tag
      size={size}
      borderRadius="full"
      variant="solid"
      colorScheme={colorScheme}
    >
      <TagLabel>{tagLabelText}</TagLabel>
      <TagCloseButton onClick={removeTag} />
    </Tag>
  );
};

export default AccountTagCloseButton;
